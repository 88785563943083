<script>


</script>

<template>
  <div>
    <HelloWorld :user="user" />
    <div v-if="user.vip" class="wrapper_redirect">
      <h2>Редирект-центр</h2>
      <div class="wrapper_redirect_inner">
        <div class="wrapper_redirect_left">
          <a href="#" class="redirect_create_link" v-on:click="showModall" >Создать категорию ссылок</a>
          <div class="wrapper_redirect_left_categories">
            <a href="#" class="redirect_categories_link">Категории ссылок</a>
            <div class="redirect_line"></div>
            <a :href="'https://'+user.login +'.neearby.com/category/all'" class="redirect_link_all">Все редиректы</a>
            <a class="redirect_without_categories" :href="'https://'+user.login +'.neearby.com/category/none'" >Без категории</a>
            <a v-for="item in arrCategories" :key = "item.id" :href="'https://'+user.login +'.neearby.com/category/'+item.id" class="redirect_link_categories">{{item.name}}</a>
          </div>
        </div>
        <div class="wrapper_redirect_right">
          <div class="redirect_right_line"></div>
          <div class="wrapper_redirect_right_inner">
            <p class="redirect_right_text_red">Экспресс редирект</p>
            <p class="redirect_right_text_choose">Выберите назначение для ссылки</p>
            <div class="block_link_icon">
              <a href="#" v-on:click="addMeaning('test')" id="meantest">
                <div class="block_icon_redirect">
                  <img src="../assets/test.svg" alt="">
                </div>
              </a>
              <a href="#" v-on:click="addMeaning('mail')" id="meanmail">
                <div class="block_icon_redirect">
                  <img src="../assets/mail.svg" alt="">
                </div>
              </a>
              <a href="#" v-on:click="addMeaning('youtube')" id="meanyoutube">
                <div class="block_icon_redirect">
                  <img src="../assets/youtube.svg" alt="">
                </div>
              </a>
              <a href="#" v-on:click="addMeaning('vk')" id="meanvk">
                <div class="block_icon_redirect">
                  <img src="../assets/vk.svg" alt="">
                </div>
              </a>
              <a href="#" v-on:click="addMeaning('yandex')" id="meanyandex">
                <div class="block_icon_redirect">
                  <img src="../assets/yandex.svg" alt="">
                </div>
              </a>
              <a href="#" v-on:click="addMeaning('instagram')" id="meaninstagram">
                <div class="block_icon_redirect">
                  <img src="../assets/instagram.svg" alt="">
                </div>
              </a>
              <a href="#" v-on:click="addMeaning('telegram')" id="meantelegram">
                <div class="block_icon_redirect">
                  <img src="../assets/telegram.svg" alt="">
                </div>
              </a>
            </div>
            <p class="redirect_placeholder_input">Вставьте ссылку</p>
            <input type="text" placeholder="Ссылка" class="redirect_input_link" v-model="url">
            <div class="block_redirect_name_categories_button">
              <div class="block_redirect_name">
                <p class="redirect_placeholder_input">Введите название</p>
                <input type="text" placeholder="Название" class="redirect_input_name" v-model="nameUrl">
              </div>
              <div class="block_redirect_categories">
                <p class="redirect_placeholder_input">Выберите категорию</p>
                <select name="" id="" class="redirect_select_categories" v-model="categori">
                  <option value="" selected class="option_choose">Без категории</option>
                  <option v-for="item in arrCategories" :key = "item.id" :value="item.id" class="option_choose">{{item.name}}</option>
                </select>
              </div>
              <div class="div_redirect_button">
                <a href="#" class="redirect_button" v-on:click="createRedirect">
                  <img src="../assets/icons8-link-60.png" alt="">
                </a>
              </div>
            </div>
            <!-- <div class="block_redirect_name_categories_button">

              <div class="mt-1">
                <p class="redirect_placeholder_input">Запланировать редирект</p>
                <date-picker v-model="scheduleRedirect" type="datetime"></date-picker>
              </div>
              
            </div> -->
            <div class="warning_redirect_center"><p class="wwarning">{{ errRedirect }}</p></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="block_v_else"><p class="block_v_else_p">Редирект доступен только для пользователей вип</p>
      <vip :user="user" /></div>
    <b-modal id="modal-redirect">
      <template #modal-header="">
        <h5>Категории</h5>
      </template>
      <template #default="">
        <h5>Название</h5>
        <input type="text" class="modal_body_input" placeholder="Название" v-model="nameCategories">
        <p class="wwarning">
          {{ err }}
        </p>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          style="margin-right: 10px"
          variant="primary"
          v-on:click="sendinsult()"
        >Сохранить</b-button
        >
        <b-button variant="secondary" @click="cancel()"> Закрыть </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-create-redirect">
      <template #modal-header="">
        <h5>Редирект успешно создан</h5>
      </template>
      <template #default="">
        <h5>Ссылка: {{linkForRedirect}}</h5>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()"> Закрыть </b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import HelloWorld from "@/components/HelloWorld.vue";

import vip from "@/components/vip.vue";
import { Auth } from "../auth";
import Api from "../api";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {

  name: "redirectCenter",
  components: {HelloWorld, vip, DatePicker},
  data(){
    return{
  user : {},
      nameCategories : "",
      err : "",
      arrCategories : [],
      arrayMeaning: [],
      url: "",
      nameUrl: "",
      categori: "",
      errRedirect: "",
      linkForRedirect: "",
      scheduleRedirect: "",
    }
  },
  methods: {
   showModall(){
     this.$bvModal.show("modal-redirect");
   },
    async createRedirect() {
     const data = {
       idCategori: this.categori,
       link: this.url,
       name: this.nameUrl,
       meaning:this.arrayMeaning,
       schedule: this.scheduleRedirect
     }
     let result = await Api.users.createRedirect(localStorage.getItem("token"), data)
      if (result.message) {
        this.errRedirect = result.message
      } else {
        this.linkForRedirect = result.link;
        this.$bvModal.show("modal-create-redirect");
      }
    },
    addMeaning(id) {
     if(this.arrayMeaning.includes(id)) {
       document.getElementById("mean"+id).classList.remove("class_gray");
       this.arrayMeaning = this.arrayMeaning.filter((item) => {
         if(item != id) {
           return true
         }
         return false
       })
     } else {
       this.arrayMeaning.push(id)
       document.getElementById("mean"+id).classList.add("class_gray");
     }
    },
    delMeaning(id) {
      document.getElementById("mean"+id).classList.remove("class_gray");
      this.arrayMeaning.filter((item) => {
        if(item != id) {
          return true
        }
        return false
      })
    },
  async sendinsult(){
     const data = {
       name: this.nameCategories
     }
  let result = await Api.users.createCategories(localStorage.getItem("token"), data)
   if(!result.message) {
     this.arrCategories.push(result);
     this.$bvModal.hide("modal-redirect");
   } else {
     this.err = result.message
   }
   }
  },
  async mounted() {
    this.user = await Auth();
    // document.cookie =
    //   "token=" + localStorage.getItem("token") + "; domain=neearby.com";
    const hosts = window.location.host.split(".");
    if (hosts.length != 3) {
      //window.location.href = "/"
    }
    this.arrCategories = await Api.users.getCategoriesRedirect(localStorage.getItem("token"))
  },
};
</script>

<style scoped>
.block_v_else_p {
  font-size: 28px;
  margin-bottom: 25px;
  padding-top: 15px;
}
.block_v_else {
  background-color: #e9e9e9;
}
.warning_redirect_center {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2px;
}
.class_gray {
  background-color: black;
}
.redirect_link_categories {
  padding-left: 15px;
  padding-bottom: 11px;
  margin-bottom: 11px;
  text-align: left;
}
.modal_body_input {
  border: 1px solid #dee2e6;
  height: 31px;
  padding-left: 7px;
}
.wrapper_redirect {
  background-color: #e9e9e9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper_redirect_inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.redirect_placeholder_input {
  margin-top: 1rem;
  text-align: left;
}
.redirect_create_link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  width: 282px;
  border: 3px solid #60A8E4;
  text-decoration: none;
  color: #2D68E0;
  margin-bottom: 9px;
  background-color: #ffffffff;
  text-align: left;
}
.wrapper_redirect_left_categories {
  width: 282px;
  border: 3px solid #8AE6A7;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  background-color: #ffffffff;
}
.wrapper_redirect_left_categories > a {
  text-decoration: none;
  color: #000000;
}
.redirect_categories_link {
  margin-left: 8px;
  font-size: 18px;
  margin-top: 11px;
  margin-bottom: 14px;
  text-align: left;
}
.redirect_line {
  width: 280px;
  border-bottom: 3px solid #8AE6A7;
  margin-left: -3px;
}
.redirect_link_all {
  margin-left: 13px;
  margin-top: 7px;
  margin-bottom: 11px;
  font-weight: bold;
  text-align: left;
}
.redirect_without_categories {
  margin-left: 13px;
  font-weight: bold;
  margin-bottom: 11px;
  text-align: left;
}
.wrapper_redirect_right {
  width: 590px;
  height: 330px;
  border-radius: 0px 15px 15px 0px;
  background-color: #ffffffff;
  display: flex;
  justify-content: flex-start;
}
.redirect_right_line {
  height: 330px;
  border-left: 5px solid #ff543e;
}
.wrapper_redirect_right_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11px;
}
.redirect_right_text_red {
  margin: 0;
  margin-top: 15px;
  font-size: 18px;
  margin-bottom: 8px;
  color: #ff543e;
  font-weight: bold;
}
.redirect_right_text_choose {
  margin: 0;
  margin-bottom: 10px;
}
.block_link_icon {
  display: flex;
  width: 558px;
  justify-content: space-between;
}
.block_icon_redirect {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #DEDDDF;
  width: 48px;
  height: 48px;
}
.block_icon_redirect > img {
  width: 38px;
}
.wrapper_redirect_left {
  margin-bottom: 15px;
}
.redirect_input_link {
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  height: 27px;
  padding-left: 10px;
  width: 544px;
}
.block_redirect_name_categories_button {
  display: flex;
}
.block_redirect_name {
  margin-right: 30px;
}
.redirect_input_name {
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
  width: 207px;
}
.redirect_select_categories {
  height: 31px;
  width: 207px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
  margin-right: 25px;
}
.redirect_button {
  height: 31px;
  width: 74px;
  background-color: #EE3B00;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.redirect_button > img {
  width: 10px;
  height: 10px;
}

.div_redirect_button {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 896px) {
  .wrapper_redirect_left {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .wrapper_redirect > h2 {
    margin-top: 15px;
  }
  .wrapper_redirect_right {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  .wrapper_redirect_left {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .redirect_input_link {
    width: 280px;
  }
  .redirect_right_line {
    height: 100%;
  }
  .wrapper_redirect_right {
    width: 320px;
    height: 100%;
  }
  .wrapper_redirect_right_inner {
    width: 100%;
    flex-wrap: wrap;
  }
  .block_link_icon {
    width: 300px;
    flex-wrap: wrap;
  }
  .block_redirect_name_categories_button {
    flex-wrap: wrap;
  }
  .div_redirect_button {
    margin-bottom: 8px;
    height: 31px;
    justify-content: center;
    margin-top: 16px;
  }
  .wrapper_redirect_left_categories {
    margin-right: 0px;
  }
  .wrapper_redirect_left_categories {
    width: 320px;
  }
  .redirect_line {
    width: 320px;
  }
  .redirect_create_link {
    width: 320px;
  }
}
</style>