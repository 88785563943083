<template>
  <div class="wrapper_white_block">
    <h2 class="white_block_name">Уведомления</h2>
    <div v-for="notif in notify" :key="notif.id" class="rectangle_white">
      <div class="rectangle_white_inner">
        <div class="block_left">
          <div class="block_left_date">
            <p>{{ notif.createdAt }}</p>
          </div>
          <div class="block_left_number">
            <p>#{{ notif.id }}</p>
            <p>{{ notif.tittle }}</p>
          </div>
          <div class="block_left_status">
            <p>{{ notif.text }}</p>
          </div>
        </div>
        <div class="block_right"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../api";

export default {
  name: "notifications",
  data() {
    return {
      notify: [],
    };
  },
  props: {},
  methods: {},
  async mounted() {
    const _resNotify = await Api.users.ReadNotify(
      localStorage.getItem("token")
    );
    console.log(_resNotify);
    this.notify = await Api.users.getNotify(localStorage.getItem("token"));
  },
};
</script>
<style lang="scss">
.wrapper_white_block {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #e9e9e9;
}
.white_block_name {
  margin-top: 50px;
}
.rectangle_white {
  border-radius: 0px 10px 10px 0px;
  width: 318px;
  height: 169px;
  background-color: #ffffff;
  display: flex;
  margin-bottom: 15px;
}
.rectangle_white_inner {
  display: flex;
  justify-content: space-between;
  height: 85px;
}
.green_line {
  height: 85px;
  width: 3px;
  background-color: #ff8437;
}
.block_left {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  height: 100%;
  justify-content: flex-start;
  margin-right: 26px;
}
.block_left_date > p {
  font-size: 10px;
}
.block_left_date :first-child {
  margin-right: 3px;
}
.block_left_date :nth-child(2) {
  margin-right: 3px;
}
.block_left_date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 6px;
}
.green_circle {
  width: 48px;
  height: 48px;
  border: 2px solid #ff9860;
  border-radius: 50%;
}
.block_left_number > p {
  font-size: 14px;
  font-weight: bold;
}
.block_left_number {
  display: flex;
  margin-bottom: 12px;
}
.block_left_number > :first-child {
  margin-right: 48px;
}
.block_left_status > p {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0px;
}
.block_left_status {
  display: flex;
}
.block_left_status :first-child {
  margin-right: 20px;
  color: #ff8134;
}
.block_left_status :nth-child(2) {
  margin-right: 3px;
}
.block_right {
  display: flex;
  justify-content: flex-end;
}
.green_circle {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.green_circle > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
