<template>
  <div class="maincont">
    <h2><p class="set" style="
    margin-left: auto;">Настройки аккаунта</p></h2>
    <h4><p class="sett">{{ user.login }}#{{ user.id }}</p></h4>
    <div class="fffforgrid">
      <div class="leftgrid">
        <div class="leftconone">
          <div>
            <label for="text-email">Имя</label>
            <b-form-input
              v-model="name"
              id="text-email"
              placeholder="Введите вашe Имя Пользователя"
            ></b-form-input>
          </div>
        </div>
        <div class="leftcontwo">
          <div>
            <label for="text-emaill">Email</label>
            <b-form-input
              v-model="email"
              id="text-emaill"
              placeholder="Введите ваш Email"
            ></b-form-input>
          </div>
          <div class="butttton" style="text-align: center;">
            <p class="error">{{ errors }}</p>
            <b-button variant="primary" @click="profileupdate()"> Сохранить</b-button>
          </div>
        </div>
        <div class="leftcontwo">
          <div class="butttton" style="text-align: center;">
            <p class="error">{{ err }}</p>
            <p class="err_good_text">{{ goodErr }}</p>
          </div>
        </div>
      </div>
      <div class="rightcontone" v-if="user.login">
        <h6>Изменение фотографии пользователя</h6>

        <div style="width: 100%; 
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    padding: 1rem 1rem;
                    background-color: rgb(255, 255, 255);"
             class="avattt">


          <div style="width: 100%;
                      height: 90%; 
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      vertical-align: middle;">
            <img style="width: 100%; height: 90%" id="avatarochka" :src="
              'https://api.neearby.com/api/users/' +
              user.login +
              '/photo'
            " alt="">
          </div>
        </div>
        <div style="margin-left: 50%; transform: translate(-50%);margin-top: 20px;" class="croperimg">

        </div>
        <div class="upload" style="
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  ">

          <button class="updatemini" v-on:click="uploadMini">Изменить миниатюру
          </button>
          <p class="succesmini" >{{ sucres }}</p>
          <b-button variant="info" style="margin-top: 20px; font-size: 15px; width: 100%">
            <input
              type="file"
              @change="uploadProf($event)"
              accept="image/*"
              id="upload"
              hidden
            />
            <label for="upload">
              <b-icon icon="image" style="margin-right: 5px; "></b-icon>
              Обновить фотографию</label>
          </b-button>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../api";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "settingsheader",
  props: {
    crop: Object,
    user: Object
  },
 async mounted() {
    let result = await Api.users.procentGet(localStorage.getItem("token"))
    this.partnerPercent = result.procent
    const ztx = this
    setTimeout(async () => {
      this.email = this.user.email;
      this.name = this.user.Name;
    }, 1000);

    setTimeout(async () => {
      const _img = document.getElementById("avatarochka");
      console.log(_img);
       ztx.crop = new Cropper(_img, {
        viewMode: 1,
        cropBoxResizable: true,
        minCropBoxWidth: 150,
        minCropBoxHeight: 150,
        dragBox: "none",
        background: false, //Whether to display the grid background
        zoomable: false, //Whether to allow zooming in the image
        guides: false, //Wh
        preview: ".croperimg",
        aspectRatio: 1 / 1,
        crop(event) {

        }
      });
    }, 1100);

  },
  data() {
    return {
      name: "",
      email: "",
      errors: "",
      sucres: "",
      goodErr: "",
      partnerPercent: "",
      err: "",
    };
  },
  methods: {
    async updatePercent() {
      const data = {
       procent: this.partnerPercent,
      }
      let result = await Api.users.procentPost(localStorage.getItem("token"), data)
      if (result.message) {
        this.err = result.message
      } else {
        this.goodErr = "Успешно"
      }
    },
    showModal() {
      if (this.user.error) {
        this.$bvModal.show("modal-account");
      } else {
        this.$refs["modacc"].show();
      }

    },
    async uploadMini(event) {
      this.sucres = 'Загрузка..'
      const _img = document.getElementById("avatarochka");
      const _l = this.crop.getData();
      const _payload = {
        x:_l.x,
        y:_l.y,
        w: _l.width,
        h: _l.height,
      }
      console.log(_payload)
      const _t = localStorage.getItem("token")
      const _r = await Api.users.updatemini(_payload, _t);
      console.log(_r);
      this.sucres = 'Успех!'
    },
    async uploadProf(event) {
      const data = new FormData();
      data.append("photo", event.target.files[0]);
      console.log(data);
      const result = await Api.users.updateProfile(
        data,
        localStorage.getItem("token")
      );
      console.log(result);
      if (result == true) {
        console.log("2");
        window.location.reload();
        document.getElementById("avatarochka").src =
          "https://api.neearby.com/api/users/" +
          user.login +
          "/photo?a=" +
          Math.floor(Math.random() * 345678);
        // this.$forceUpdate();
      }

    },
    async profileupdate() {
      const user = {
        Name: this.name,
        email: this.email
      };
      let result = await Api.users.updateproff(user, localStorage.getItem("token"));
      if (result.message) {
        this.errors = result.message;
      }
      window.location.reload();

    }
  }
};
</script>
<style lang="scss" scoped>
.err_good_text {
  width: 100%;
  text-align: left;
  margin-top: 15px;
  font-size: 25px;
  color: #00ff00;
}
.maincont {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  background-color: #e9e9e9;
}

.leftgrid {
  width: 90%;
  height: 400px;
  padding-right: 20px;
}

.rightcontone {

}

.leftconone {

}

.leftcontwo {
  margin-top: 50px;
}

.fffforgrid {
  margin-top: 50px;
  display: flex;
  grid-template-columns: 1fr 1fr;
}

.avattt {
  width: 100%;
}

.set {
  text-align: center;
  font-weight: 700;
  padding-top: 30px;
}

.sett {
  text-align: center;
  font-weight: 700;
}

.butttton {
  margin-top: 10%;
}

.croperimg {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 100%;
}
.succesmini{
  color: rgb(13, 77, 13);
  font-size: 16px;
}
.updatemini{
  width: 100%;
  margin-top: 20px;     
  background-color: #fe6637;
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fe6637;
  border-radius: 6px;
  font-weight: 650;
  font-family: Montserrat,sans-serif;
  transition: .5s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .fffforgrid {
    display: flex;
    flex-direction: column;
  }
  .leftgrid {
    width: 100%;
    height: 250px;
  }
  #modal-account {
    width: 320px;
    margin-left: 50%;
    transform: translate(-50%, 0%);
    font-size: 16px;
  }
}
</style>
