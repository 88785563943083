<template>
  <div style="padding: 0px 15px;min-height: 80vh;">
    <div class="container__container">
      <div class="administration">
        <div class="administration__orders">
          <div class="order__statistics">
            <div class="order__items">
              <img style="margin-right: 15px;" src="../assets/stats.png" alt="">
              <p style="font-size: 22px;">Моя статистика</p>
            </div>
          </div>
          <div class="block_money_statisticks">
            <p style="font-size: 22px;color: #333;display: inline-block; margin: 0px;">Ваш баланс: {{ Number(user.balance).toLocaleString('ru-RU') }} руб</p>
          </div>
          <div class="orders__selection">
            <div class="selection__time">
              <img style="margin-right: 15px;" src="../assets/time.png" alt="">
              <div>
                <p style="display: inline-block; margin: 0;">Показывать заказы за</p>
                <select @change="updatetable($event)" v-model="typedata"  class="select">
                  <option class="option" value="day">
                    <span style="padding: 10px;" class="title">Сегодня</span>
                  </option>
                  <option class="option" value="weak">
                    <span style="padding: 10px;" class="title">Неделя</span>
                  </option>
                  <option class="option" value="mounth">
                    <span style="padding: 10px;" class="title">Месяц</span>
                  </option>
                  <option class="option" value="year">
                    <span style="padding: 10px;" class="title">Год</span>
                  </option>
                  <option class="option" value="full">
                    <span style="padding: 10px;" class="title">Все время</span>
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- <div class="orders__filter">
            <div class="filter__stat">
              <img style="margin-right: 15px;" src="../assets/filter.png" alt="">
              <p style="margin-top: 30%;">Фильтр</p>
            </div>
            v-for=" in weak" :key="" v-if="selectedstats == 'weak'"
          </div> -->
        </div>
      </div>
      <div class="order">
        <div class="card__card">
          <div class="blue__card">
            <div style="margin-top: 5px;">
              <p class="card__money"> {{ allstats.toLocaleString('ru-RU') }} <span>руб</span></p>
            </div>
            <div class="cards">
            </div>
            <div class="stat__card">
              <p class="stat__all__blue">ВСЕГО</p>
              <p class="stat__total__blue"> {{ allstats1 }} </p>
            </div>
          </div>
        </div>
        <div class="card__card">
          <div class="green__card">
            <div style="margin-top: 5px;">
              <p class="card__money"> {{ successstat.toLocaleString('ru-RU') }} <span>руб</span></p>
            </div>
            <div class="cards">
            </div>
            <div class="stat__card">
              <p class="stat__all__green">ОПЛАЧЕНО</p>
              <p class="stat__total__green"> {{ successstat1 }} </p>
            </div>
          </div>
        </div>
        <div class="card__card">
          <div class="orange__card">
            <div style="margin-top: 5px;">
              <p class="card__money"> {{ cancelledstat.toLocaleString('ru-RU') }} <span>руб</span></p>
            </div>
            <div class="cards">
            </div>
            <div class="stat__card">
              <p class="stat__all__orange">ОТМЕНЕНО</p>
              <p class="stat__total__orange"> {{ cancelledstat1 }} </p>
            </div>
          </div>
        </div>
        <div class="card__card">
          <div class="red__card">
            <div style="margin-top: 5px;">
              <p class="card__money"> {{ notpaid.toLocaleString('ru-RU') }} <span>руб</span></p>
            </div>
            <div class="cards">
            </div>
            <div class="stat__card">
              <p class="stat__all__red">ВОЗВРАТ</p>
              <p class="stat__total__red"> {{ notpaid1 }} </p>
            </div>
          </div>
        </div>
        <div class="card__card">
          <div class="purple__card">
            <div style="margin-top: 5px;">
              <p class="card__money"> {{ neopl.toLocaleString('ru-RU') }} <span>руб</span></p>
            </div>
            <div class="cards">
            </div>
            <div class="stat__card">
              <p class="stat__all__purple">НЕ ОПЛАЧЕНО</p>
              <p class="stat__total__purple"> {{ neopl1 }} </p>
            </div>
          </div>
        </div>
        <div class="card__card">
          <div class="yellow__card">
            <div style="margin-top: 5px;">
              <p class="card__money">Скоро <span></span></p>
            </div>
            <div class="cards">
            </div>
            <div class="stat__card">
              <p class="stat__all__yellow">ЧАСТИЧНО</p>
              <p class="stat__total__yellow">0</p>
            </div>
          </div>
        </div>

      </div>

  <div class="aaaaa">
      <table class="fold-table">
        <thead>
        <tr style="background: white;">
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">№
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Услуга
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Статус
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Создан
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Оплачено
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Сумма
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Метка
          </th>
          <th style="color: #666666;
  font-weight: normal;
  font-size: 13px;    border-bottom: 1px solid #E6E6E6;
    border-top: none;
    padding: 30px;
    vertical-align: middle;">Оплата
          </th>
        </tr>
        </thead>
        <tbody id="stats"  class="lepsiha">
          <tablestat  v-for="stat in stats" :key="stat.id" :data="stat"/>
        </tbody>
       <tbody id="stats1"  style="display:none" class="lepsiha">
          <tablestat  v-for="stat in stats1" :key="stat.id" :data="stat"/>
        </tbody>
          <tbody id="stats2"   style="display:none"  class="lepsiha">
          <tablestat  v-for="stat in stats2" :key="stat.id" :data="stat"/>
        </tbody>

        <tbody id="stats3"   style="display:none" class="lepsiha">
          <tablestat  v-for="stat in stats3" :key="stat.id" :data="stat"/>
        </tbody>

          <tbody  id="stats4"  style="display:none" class="lepsiha">
          <tablestat  v-for="stat in stats4" :key="stat.id" :data="stat"/>
        </tbody>
      </table>
  </div>
    </div>
  </div>
</template>
<script>
import Api from "../api";
import tablestat from "../components/tablestat.vue";

export default {
  name: "teststatisticks",
  components: {tablestat}, 
  props: {
    user: Object
  },
  methods: {
    accord(event){
      console.log(1);
      console.log(event.target);
      event.target.querySelector(".view").classList.toggle("open");
      event.target.querySelector(".fold").classList.toggle("open");
    },
   async updatetable(event){
      const stats = await Api.users.getnewstats(localStorage.getItem("token"), this.typedata);
        // this.fixtablestat()
        this.allstats = stats.score.all;
        this.successstat = stats.score.success;
        this.notpaid = stats.score.refund;
        this.cancelledstat = stats.score.rejected;
        this.allstats1 = stats.score.all1;
        this.successstat1 = stats.score.success1;
        this.notpaid1 = stats.score.refund1;
        this.cancelledstat1 = stats.score.rejected1;
        this.neopl = stats.score.neopl;
        this.neopl1 = stats.score.neopl1;
        if(this.typedata == 'day') {
          this.stats = stats.scoreData;
          document.getElementById('stats').style.display = 'table-row-group'
          document.getElementById('stats1').style.display = 'none'
          document.getElementById('stats2').style.display = 'none'
          document.getElementById('stats3').style.display = 'none'
          document.getElementById('stats4').style.display = 'none'
        }
        if(this.typedata == 'weak') {
          this.stats1 = stats.scoreData;
          document.getElementById('stats').style.display = 'none'
          document.getElementById('stats1').style.display = 'table-row-group'
          document.getElementById('stats2').style.display = 'none'
          document.getElementById('stats3').style.display = 'none'
          document.getElementById('stats4').style.display = 'none'
        }
        if(this.typedata == 'mounth') {
          this.stats2 = stats.scoreData;
          document.getElementById('stats').style.display = 'none'
          document.getElementById('stats1').style.display = 'none'
          document.getElementById('stats2').style.display = 'table-row-group'
          document.getElementById('stats3').style.display = 'none'
          document.getElementById('stats4').style.display = 'none'
        }
        if(this.typedata == 'year') {
          this.stats3 = stats.scoreData;
          document.getElementById('stats').style.display = 'none'
          document.getElementById('stats1').style.display = 'none'
          document.getElementById('stats2').style.display = 'none'
          document.getElementById('stats3').style.display = 'table-row-group'
          document.getElementById('stats4').style.display = 'none'
        }
        if(this.typedata == 'full') {
          this.stats4 = stats.scoreData;
          document.getElementById('stats').style.display = 'none'
          document.getElementById('stats1').style.display = 'none'
          document.getElementById('stats2').style.display = 'none'
          document.getElementById('stats3').style.display = 'none'
          document.getElementById('stats4').style.display = 'table-row-group'
        }
        this.fixtable()
        console.log(this.stats)
    },
    async fixtable(){
      setTimeout( ()=>{
      const restable = document.querySelectorAll('.dde')
      console.log(restable)
      restable.forEach(el => {
        const childrens = el.querySelectorAll('tr')
        const parent = el.parentElement
        childrens.forEach((item) => { parent.appendChild(item) })
        parent.removeChild(el)
      });
    },50 )
    },
     fixtablestat(){
      const restable = document.querySelectorAll('.view')
      console.log(restable)
      restable.forEach(el => {
        const rel = el.nextSibling
        rel.parentNode.removeChild(rel)
        el.parentNode.removeChild(el)

        // const childrens = el.querySelectorAll('tr')
        // const parent = el.parentElement
        // childrens.forEach((item) => { parent.appendChild(item) })
        // parent.removeChild(el)
      });
    }
  },
  computed: {
    _updatedstats:function() {
      // this.fixtablestat()
      // this.fixtable() 
      return this.stats.reverse() }      
  },
  data() {
    return {
      alladata: {},
      stats: [],
      allstats: "",
      successstat: "",
      notpaid: "",
      cancelledstat: "",
      allstats1: "",
      successstat1: "",
      notpaid1: "",
      cancelledstat1: "",
      neopl: "",
      neopl1: "",
      typedata: "day",
      stats1: false,
      stats2: false,
      stats3: false,
      stats4: false,
    };
  },
  async mounted() {
    let result = await Api.users.statick(localStorage.getItem("token"));
    console.log(result);
    this.alladata = result;




    const stats = await Api.users.getnewstats(localStorage.getItem("token"), "day");
    this.allstats = stats.score.all;
    this.successstat = stats.score.success;
    this.notpaid = stats.score.refund;
    this.cancelledstat = stats.score.rejected;
    this.cancelledstat1 = stats.score.rejected1;
    this.allstats1 = stats.score.all1;
    this.successstat1 = stats.score.success1;
    this.notpaid1 = stats.score.refund1;
    this.neopl = stats.score.neopl;
    this.neopl1 = stats.score.neopl1;
    this.stats = stats.scoreData;
    setTimeout( ()=>{
      const restable = document.querySelectorAll('.dde')
      console.log(restable)
      restable.forEach(el => {
        const childrens = el.querySelectorAll('tr')
        const parent = el.parentElement
        childrens.forEach((item) => { parent.appendChild(item) })
        parent.removeChild(el)
      });
    },100 )
  }
}
</script>
<style>
.block_money_statisticks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  padding: 25px;
}
.container__container {
  margin: 0 auto;
  width: 95%;
}

.administration {
  width: 100%;
}

.administration__orders {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  justify-content: center;
}

.order__statistics {
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.filter__stat {
  padding: 25px;
  min-height: 85px;
  line-height: 35px;
  display: grid;
  grid-template-columns: .1fr 1fr;
  align-items: center;
}

.order__items {
  padding: 25px;
  min-height: 85px;
  line-height: 35px;
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
}

.order__items > p {
  color: #333333;
  display: inline-block;
  margin-top: 15%;
}

.orders__selection {
  width: 50%;
  display: flex;
  justify-content: left;
}

.selection__time {
  padding: 25px;
  min-height: 85px;
  line-height: 35px;
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  cursor: pointer;
}

.order {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.blue__card {
  height: 100%;
  width: 100%;
  background-color: #e5f0fc;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  padding: 15px;
}

.card__card {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 4px;
  background-color: rgb(225, 217, 239);
  box-shadow: 1px 1.732px 6px 0px rgb(0 0 0 / 10%);
  width: 200px;
  height: 120px;
  margin: 0px 5px 10px;
  cursor: pointer;
}

.cards {
  display: flex;
  border-bottom: 2px solid white;
}

.card__money {
  font-size: 18px;
  font-family: "Roboto";
  color: rgb(34, 34, 34);
  text-align: left;
}

.card__money > span {
  font-size: 14px;
  font-weight: normal;
  color: #6b6b6b;
}

.stat__card {
  display: flex;
  align-items: center;
  line-height: 20px;
  padding-top: 10px;
  font-size: 13px;
  font-family: "Roboto";
}

.stat__all__blue {
  color: #024fa5;
}

.stat__total__blue {
  padding: 1px 20px;
  background: #428bca;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  border-radius: 5px;
}

.green__card {
  height: 100%;
  width: 100%;
  background-color: #E8F4DE;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  padding: 15px;
}

.stat__all__green {
  color: #35a10d;
}

.stat__total__green {
  padding: 1px 20px;
  background-color: #6CC722;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  border-radius: 5px;
}

.purple__card {
  height: 100%;
  width: 100%;
  background-color: rgb(225, 217, 239);
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  padding: 15px;
}

.stat__all__purple {
  color: #a096af;
}

.stat__total__purple {
  padding: 1px 20px;
  background-color: #999;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  border-radius: 5px;
}

.orange__card {
  height: 100%;
  width: 100%;
  background-color: #F5EDE4;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  padding: 15px;
}

.stat__all__orange {
  color: #F6A02F;
}

.stat__total__orange {
  padding: 1px 20px;
  background: #F6A02F;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  border-radius: 5px;
}

.red__card {
  height: 100%;
  width: 100%;
  background-color: #FDEDF2;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  padding: 15px;
}

.stat__all__red {
  color: #FA316F;
}

.stat__total__red {
  padding: 1px 20px;
  background-color: #FA316F;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  border-radius: 5px;
}

.yellow__card {
  height: 100%;
  width: 100%;
  background-color: #e1eeff;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  padding: 15px;
}

.stat__all__yellow {
  color: #2196f3;
}

.stat__total__yellow {
  padding: 1px 20px;
  background-color: #2196f3;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  border-radius: 5px;
}

.select {
  position: absolute;
  width: 165px;
  overflow: hidden;
  display: block;
  font-size: 13px;
  color: #333333;
  font-weight: 600;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
}

.selected::after {
  padding: 0.25em 0.5em;
}

.select .toggle {
  position: absolute;
  z-index: 4;
  right: 1.5em;
  top: 1.6em;
  color: #ccc;
}

.selection__style {
  position: relative;
  text-align: left;
  display: block;
  background: #fff;
  z-index: 10;
  min-width: 280px;
  border-radius: 6px;
  box-shadow: 3px 5.196px 8px 0px rgb(204 204 204 / 73%);
}

.select .title, .select .placeholder {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: white;
  cursor: pointer;
}

.select > input {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  cursor: pointer;
}

.select > input:checked ~ i.toggle.icon-arrow-down {
  display: none;
}

.select > input:checked ~ i.toggle.icon-arrow-up {
  display: block;
}

.select > input:checked div.options label.option .title {
  display: none !important;
}

.select > input:not(:checked) {
  z-index: 4;
}

.select > input:not(:checked) ~ label.option > span.title {
  display: none;
}

.select > input:not(:checked) ~ i.toggle.icon-arrow-up {
  display: none;
}

.select > input:not(:checked) ~ i.toggle.icon-arrow-down {
  display: block;
}

.select > input:disabled {
  cursor: no-drop;
}

.select > span.placeholder {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  color: #999;
  border-top: 0px;
}

.select label.option {
  display: block;
  overflow: hidden;
  z-index: 1;
  transition: all 1s ease-out;
  background: #fff;
  z-index: 3;
  text-align: left;
  min-width: 280px;
  box-shadow: 3px 5.196px 8px 0px rgb(204 204 204 / 73%);

}

.select label.option span.title {
  position: relative;
  z-index: 2;
  font-size: 13px;
  color: #666666;
  text-align: left;
  background: #F7F7F7;
}

.select label.option span.title i.icon {
  padding-right: 8px;
  color: #92a8d1;
}

.select label.option span.title:hover {
  color: ;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.select label.option input {
  display: none;
}

.select label.option input:checked ~ span.title {
  position: absolute;
  display: block;
  z-index: 3;
  top: 0px;
  font-size: 12px;
  background: #fff;
  border-top: 0px;
  box-shadow: none;
  color: inherit;
  width: 100%;
}

.select label.option input:disabled ~ span.title {
  background: #f9f9f9 !important;
  color: #aaa;
}

.select label.option input:disabled ~ span.title:hover {
  color: #aaa;
  background: none;
  cursor: no-drop;
}

.orders__filter {
  width: 33%;
  display: flex;
  justify-content: left;
  cursor: pointer;
}

.fold-table {
  width: 100%;
  margin: 0 auto;
}

.view > td {
  text-align: center;
  color: #333333;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #E6E6E6;
  border-top: none;
  padding: 15px 5px;
  vertical-align: middle;
}

.fold-table > th {
  text-align: center;
  border-bottom: 1px solid #ccc;
  color: #666666;
  font-weight: normal;
  font-size: 13px;
}

.fold-table > table th, table td {
  padding: 0.4em;

}

.fold-table > table.fold-table > tbody > tr.view td, table.fold-table > tbody > tr.view th {
  cursor: pointer;
}

.fold-table > table.fold-table > tbody > tr.view td:first-child, table.fold-table > tbody > tr.view th:first-child {
  position: relative;
  padding-left: 20px;
}

table.fold-table > tbody > tr.view td:first-child:before, table.fold-table > tbody > tr.view th:first-child:before {
  position: absolute;
  top: 50%;
  left: 5px;
  width: 9px;
  height: 16px;
  margin-top: -8px;
  font: 16px fontawesome;
  color: #999;
  transition: all 0.3s ease;
}

table.fold-table > tbody > tr.view:nth-child(4n-1) {
  background: #eee;
}

table.fold-table > tbody > tr.view.open {

}

table.fold-table > tbody > tr.view.open td:first-child:before, table.fold-table > tbody > tr.view.open th:first-child:before {
  transform: rotate(-180deg);
  color: #333;
}

table.fold-table > tbody > tr.fold {
  display: none;
}

table.fold-table > tbody > tr.fold.open {
  display: table-row;
}

.fold-content {
  padding: 0.5em;
}

.fold-content h3 {
  margin-top: 0;
}

.fold-content > table {
  border: 2px solid #ccc;
}

.fold-content > table > tbody tr:nth-child(even) {
  background: #eee;
}

.acor__user__information {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 50px;
}
.user__text{
  position: relative;
  left: -60px;
  padding-top: 30px;
}
.user__information {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}

.user__photo {
  margin-right: 10px;
}

.itemsss {
  overflow-y: auto;
  margin-top: 15px;
  border-top: 1px solid whitesmoke;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.user__items {
  padding-left: 40px;
}
.nomertable:after{
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
@media only screen and (max-width: 1470px) {
  .order__statistics {
    justify-content: left;
  }
}
@media only screen and (max-width: 600px) {
  .administration__orders {
    flex-direction: column;
    align-items: center;
  }

  .order__items {
    padding: 0;
  }

  .selection__time {
    padding: 0;
  }

  .filter__stat {
    padding: 0;
  }

  .orders__selection {
    width: 100%;
    justify-content: center;
  }

  .order__statistics {
    width: 100%;
    justify-content: center;
  }
  .user__text{
  left: 0px;
  padding-top: 15px;
}
}
</style>
