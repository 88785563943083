<template>
  <div></div>
</template>

<script>
import Api from "../api";
export default {
  name: "redirect-сenter",
  data(){
    return{

    }
  },
 async mounted() {
   const _hosts = window.location.host.split(".");
   console.log(_hosts);
   const ter = this.$route.params.link;
   if (_hosts.length != 3) {
     return 0;
   }
   const data = {
     //"refer": document.referrer,
     "login": _hosts[0],
     "link": ter,
   };
   console.log(data)
  const result = await Api.users.goToRedirect(data)
  console.log(result);
   if(!result.message) {
     window.location.href = result
   }
   console.log(result)
  }
};
</script>

<style scoped>

</style>