<template>
  <div>
    <adminheader />
    <adminnsec />
  </div>
</template>

<script>
// @ is an alias to /src
import adminheader from "../components/adminheader.vue";
import adminnsec from "../components/adminnsec.vue";
import { Auth } from "../auth";

export default {
  name: "Home",
  components: {
    adminheader,
    adminnsec
  },
  data() {
    return {
      user: {}
    };
  },
  async mounted() {
    this.user = await Auth();
    if (this.user.id !== 91 && this.user.id !== 3 && this.user.id !== 2 && this.user.id !== 1 && this.user.id !== 1067) {
      window.location.href = "/";
    }
  }
};
</script>
