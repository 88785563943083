<template>
  <div>
    <HelloWorld :user="user"/>
    <notifications />

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import notifications from "@/components/notifications.vue";
import {Auth} from "../auth"

export default {
  name: "Home",
  components: {
    HelloWorld,
    notifications,
  },
  data() {
    return {
      user: {}
    }
  },
  async mounted(){
    
    this.user = await Auth();
  }
};
</script>