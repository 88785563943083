<template>
<div>
  <HelloWorld :user="user" />
  <div class="wrapper_analytics">
    <div class="block_text_analytics">
      <h2 class="main_text_analytics">История переходов</h2>
      <p class="text_analytics_year">Статистика по редиректу доступна за последний год</p>
    </div>
    <div class="block_analytics_table_rectangle">
     <div class="block_table">
       <b-table
         id="my-table"
         :fields="fields"
         :items="users"
         :per-page="perPage"
         :current-page="currentPage"
         small
         class="table_redirect"
       >
         <template #cell(Страна)="data">
<div class="name_table_redirect_analytics">{{data.item.country}}</div>
         </template>
         <template #cell(Браузер)="data">
<div class="name_table_redirect_analytics">{{data.item.info.Browser.split(",").pop().split(";").shift()}}</div>
         </template>
         <template #cell(Источник)="data">
<div class="name_table_redirect_analytics"> <p v-if="data.item.info.refer !=''">{{data.item.info.refer}}</p> <p v-else >Не определено</p></div>
         </template>
         <template #cell(Время)="data">
<div class="name_table_redirect_analytics">{{ new Date(data.item.createdAt).toLocaleString('ru-RU').replace(',', '').slice(0, -3) }}</div>
         </template>
         <template #cell(IP)="data">
<div class="name_table_redirect_analytics">{{data.item.ip}}</div>
         </template>
       </b-table>
     </div>
      <div class="block_rectangle_analytics">
        <div class="block_rectangle_analytics1">
        <p class="number_rectangle_analytics_green">{{ redirect.clicks.all }}</p>
          <p class="text_placeholder_analytics">Всего просмотров</p>
        </div>
        <div class="block_rectangle_analytics1">
          <p class="number_rectangle_analytics_blue">{{ redirect.clicks.unical }}</p>
          <p class="text_placeholder_analytics">Уникальных просмотров</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { Auth } from "../auth";
import Api from "../api";
export default {
  name: "redirectAnalytics",
  components: {HelloWorld},
  data() {
    return {
      user : {},
      userw: false,
      fields: [
        "Страна",
        "Браузер",
        "Источник",
        "Время",
        "IP",
      ],
      users: {},
      perPage: 50,
      currentPage: 1,
      category: {},
      categoryName: "",
      statusCategory: false,
      err: "",
      redirect: {},
    };
  },
  computed: {
    rows() {
      return this.users.length;
    },
  },
  async mounted() {

    this.user = await Auth();
    const result = await Api.users.getAnalytics(localStorage.getItem("token") ,this.$route.params.type)
    this.users = result.stats
    this.redirect = result
  },
};
</script>
<style scoped>
.name_table_redirect_analytics{
  max-width: 300px;
  word-wrap: break-word;
  display: flex;
  width: 100%;
  text-align: center;
  min-height: 100px;
  flex-direction: column;
  justify-content: center;
}
.number_rectangle_analytics_blue {
  color: #0095b6;
  font-size: 30px;
  margin: 0px;
  font-weight: bold;
}
.text_placeholder_analytics {
  color: #808080;
}
.number_rectangle_analytics_green {
  color: #00ff7f;
  font-size: 30px;
  margin: 0px;
  font-weight: bold;
}
.block_analytics_table_rectangle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.block_rectangle_analytics1 {
  width: 100%;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 25px;
  flex-direction: column;
}
.block_rectangle_analytics {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.block_table {
  width: 68%;
  height: 100%;
}
.wrapper_analytics {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
  background-color: #e9e9e9;
  min-height: 100vh;
  flex-direction: column;
}
.block_text_analytics {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.block_text_analytics > h2, .block_text_analytics > p, .block_text_analytics > a {
  text-align: left;
  margin: 0;
  margin-bottom: 5px;
}
.main_text_analytics {
  color: #900090;
  font-size: 30px;
}
.text_analytics_link {
  color: #708090;
  text-decoration: none;
  width: 100%;
}
.text_analytics_rb {
  font-size: 20px
}
.text_analytics_year {
  font-weight: bold;
  font-size: 18px;
}
</style>