<template>
  <div>
    <HelloWorld :user="user" />
    <div class="wrapper_edit_category">
      <div class="wrapper_edit_category_inner">
        <div class="block_edit_category_text">
          <h2>Основные настройки</h2>
        </div>
        <div class="mark_img_category">
          <p class="mark_img_category_text">Пометить как (можно выбрать несколько меток)</p>
         <div class="mark_img_category_inner">
           <div class="mark_img_category_inner">
             <a v-on:click="addMeaning('test')" id="meantest">
               <div class="block_icon_redirect">
                 <img src="../assets/test.svg" alt="">
               </div>
             </a>
             <a  v-on:click="addMeaning('mail')" id="meanmail">
               <div class="block_icon_redirect">
                 <img src="../assets/mail.svg" alt="">
               </div>
             </a>
             <a  v-on:click="addMeaning('youtube')" id="meanyoutube">
               <div class="block_icon_redirect">
                 <img src="../assets/youtube.svg" alt="">
               </div>
             </a>
             <a  v-on:click="addMeaning('vk')" id="meanvk">
               <div class="block_icon_redirect">
                 <img src="../assets/vk.svg" alt="">
               </div>
             </a>
             <a  v-on:click="addMeaning('yandex')" id="meanyandex">
               <div class="block_icon_redirect">
                 <img src="../assets/yandex.svg" alt="">
               </div>
             </a>
             <a  v-on:click="addMeaning('instagram')" id="meaninstagram">
               <div class="block_icon_redirect">
                 <img src="../assets/instagram.svg" alt="">
               </div>
             </a>
             <a  v-on:click="addMeaning('telegram')" id="meantelegram">
               <div class="block_icon_redirect">
                 <img src="../assets/telegram.svg" alt="">
               </div>
             </a>
         </div>
          </div>
        </div>
        <div class="block_category_edit_categories_name">
          <div class="block_redirect_categories">
            <p class="redirect_placeholder_input">Выберите категорию</p>
            <select name="" id="" class="redirect_select_categories" v-model="category">
              <option value="0" selected class="option_choose">Без категории</option>
              <option v-for="item in allCategory" :key = "item.id" :value="item.id" class="option_choose">{{item.name}}</option>
            </select>
          </div>
          <div class="block_redirect_name">
            <p class="redirect_placeholder_input">Введите название</p>
            <input type="text" placeholder="Название" class="redirect_input_name" v-model="redirect.name">
          </div>
        </div>
        <div class="block_category_edit_link">
          <p class="redirect_placeholder_input">Ссылка</p>
          <input type="text" placeholder="Ссылка" class="redirect_input_name_link" v-model="redirect.link">
        </div>
        <div class="block_textarea_edit_category">
          <p class="textarea_edit_placeholder">Описание</p>
          <textarea name="" id="" cols="30" rows="3" placeholder="Описание" class="textarea_edit_category" v-model="redirect.desc" ></textarea>
        </div>
          <div class="block_edit_category_text2">
            <h2>Расширенные настройки</h2>
          </div>
        <div class="block_category_edit_categories_name">
          <div class="block_redirect_categories">
            <p class="redirect_placeholder_input">Псевдоним</p>
            <div class="inputs_nickname_redirect">
              <input type="text" class="redirect_input_nick_name1" disabled :value="'https://'+user.login +'.neearby.com/l/'">
              <input type="text" class="redirect_input_nick_name2" v-model="nickname">
            </div>
          </div>
        </div>
<!--          <div class="utm_block_warning_blue">-->
<!--          <p>Если вы хотите отслеживать заказы по данному редиректу, не заполняйте эти поля UTM-меток!</p>-->
<!--        </div>-->
        <div class="block_edit_category_text2">
          <h2>Условные цели</h2>
        </div>
        <div class="block_rectangle_grey">
          <div class="class_rectangle_grey_small">
            <p class="rectangle_grey_small_text">Условные цели</p>
            <a class="class_rectangle_grey_small_plus" v-if="condition.length == 0" v-on:click="addCondition" id="buuton_plus">+</a>
          </div>
          <div class="class_rectangle_grey_middle">
            <p class="class_rectangle_grey_middle_text">Условные цели переопределяют цель по умолчанию, согласно заданному условию.</p>
            <div class="class_rectangle_grey_middle_inner" v-if="condition.length != 0">
              <div class="rectangle_grey_middle_inner1">
                <p class="text_grey_middle_redirect">Тип условия</p>
                <div class="block_rectagles_grey_redirect">
                  <div :class="{type_rectangle_grey:true,class_grey:() => {
                    if(typeCondition == 'clock') {
                      return true
                    } return false
                  }}" id="button_clock" v-on:click="toggleType1">
                    <img src="../assets/clock_hour_minute_second_time_timer_watch_icon_123193.svg" alt="">
                  </div>
                  <div class="type_rectangle_grey" id="button_w" v-on:click="toggleType2">
                    <img src="../assets/letter_w_icon_151238.svg" alt="">
                  </div>
                </div>
              </div>
              <div v-if="typeCondition == 'clock'" class="two_rectangle_middle">
                <div class="rectangle_grey_middle_inner2">
                  <p class="text_grey_middle_redirect">Дата</p>
                  <input type="datetime-local" class="input_utm_redirect" v-model="dateClock">
                </div>
                <div class="rectangle_grey_middle_inner3">
                  <p class="text_grey_middle_redirect">Новая ссылка</p>
                  <input type="text" placeholder="Новая ссылка" class="input_utm_redirect" v-model="linkClock">
                </div>
                <div class="rectangle_grey_middle_inner4" v-on:click="delConditionAll">
                  <a class="class_rectangle_grey_small_cross"><p class="class_rectangle_grey_small_p_cross">x</p></a>
                </div>
              </div>
              <div v-if="typeCondition == 'w'" class="block_w">
                <div class="block_w_up">
                  <div class="input_text">
                    <p class="text_input_text">Начало</p>
                    <input type="datetime-local" class="inputs_w_block" v-model="dateInputEdit">
                  </div>
                 <div class="display_flex_up_down_main">
                   <div class="display_flex_up_down" v-for="item in arrayInterval" :key="item.id">
                     <div class="input_text" >
                       <p class="text_input_text">Интервал</p>
                       <select name="" id="" v-model="arrayInterval[item.id].option"  class="inputs_w_block">
                         <option value="10" selected class="option_choose">10м</option>
                       </select>
                     </div>
                     <div class="input_text">
                       <p class="text_input_text">Новая ссылка</p>
                       <input type="text" class="inputs_w_block" v-model="arrayInterval[item.id].link">
                     </div>
                     <div class="rectangle_grey_middle_inner4" v-on:click="delLinkFromArrayCondition(item.id)">
                       <a class="class_rectangle_grey_small_cross"><p class="class_rectangle_grey_small_p_cross">x</p></a>
                     </div>
                   </div>
                 </div>
                </div>
                <div class="block_w_down">
                  <div class="block_continuation_w">
                    <a v-on:click="addLinkInterval">Добавить ссылку</a>
                  </div>
                  </div>
              </div>
<!--              <div class="rectangle_grey_middle_inner4">-->
<!--                <a href="#" class="class_rectangle_grey_small_cross"><p class="class_rectangle_grey_small_p_cross">x</p></a>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <div class="warning_redirect_center"><p class="wwarning">{{ err }}</p></div>
        <div class="button_category_edit">
          <a class="button_category_edit1" v-on:click="saveRedirect">Сохранить и продолжить</a>
          <a href="#" class="button_category_edit2" v-on:click="saveRedirect2">Сохранить и вернуться к списку</a>
        </div>
        <div class="err_good"><p class="err_good_text">{{ goodErr }}</p></div>
        </div>
      </div>
    </div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { Auth } from "../auth";
import Api from "../api";
export default {
  name: "categoryEdit",
  components: {HelloWorld},
  data(){
    return{
      user : {},
      redirect: {},
      category: "",
      name: "",
      description: "",
      allCategory: [],
      nickname: "",
      arrayMeaning: [],
      condition: [],
      data1: "",
      data2: "",
      link: "",
      arrayInterval: [],
      typeCondition: "",
      dateInputEdit: "",
      err: "",
      goodErr: "",
    }
  },
  async mounted() {
    this.user = await Auth();
    this.redirect = await Api.users.getRedirect(localStorage.getItem("token"), this.$route.params.type)
    if(this.redirect.message ) {
      window.location.href = "/category/all"
    }
    this.allCategory = await Api.users.getCategoriesRedirect(localStorage.getItem("token"))
    this.category = this.redirect.id_category
   if (this.redirect.pseudonym) {
     this.nickname = this.redirect.pseudonym
   } else {
     this.nickname = this.redirect.linkSolid
   }
   this.arrayMeaning = this.redirect.meaning
    this.arrayMeaning.forEach((item) => {
      document.getElementById("mean"+item).classList.add("class_gray");
    })
    this.condition = this.redirect.condition
    if (this.redirect.condition.length != 0) {
      if (this.redirect.condition[0].type == "w"){
        this.toggleType2()
        this.arrayInterval = this.redirect.condition[0].context
        this.dateInputEdit = this.redirect.condition[0].date
      }
      if (this.redirect.condition[0].type == "clock"){
        this.toggleType1()
        this.dateClock = this.redirect.condition[0].context.date
        this.linkClock = this.redirect.condition[0].context.link
      }
    }
  },
  methods: {

   async saveRedirect() {
      const data = {
        name: this.redirect.name,
        desc: this.redirect.desc,
        id_category: this.category,
        meaning: this.arrayMeaning,
        link: this.redirect.link,
        pseudonym: this.nickname,
        condition: {
          type: this.typeCondition,
        }
      }
      if (this.typeCondition == "clock" ) {
        data.condition['context'] = {
          date: this.dateClock,
           link: this.linkClock,
        }
      }
      if (this.typeCondition == "w" ) {
        data.condition['context'] = this.arrayInterval
        data.condition['date'] = this.dateInputEdit
      }
      let result = await Api.users.putRedirect(localStorage.getItem("token"), data, this.$route.params.type)
     console.log(result)
     this.goodErr = ""
     this.err = ""
     if (result.message) {
       this.err = result.message
       console.log("gfodjgdougdgodfn")
     } else {
      this.goodErr = "Успешно"
     }
    },
    async saveRedirect2() {
      const data = {
        name: this.redirect.name,
        desc: this.redirect.desc,
        id_category: this.category,
        meaning: this.arrayMeaning,
        link: this.redirect.link,
        pseudonym: this.nickname,
        condition: {
          type: this.typeCondition,
        }
      }
      if (this.typeCondition == "clock" ) {
        data.condition['context'] = {
          date: this.dateClock,
          link: this.linkClock,
        }
      }
      if (this.typeCondition == "w" ) {
        data.condition['context'] = this.arrayInterval
        data.condition['date'] = this.dateInputEdit
      }
      let result = await Api.users.putRedirect(localStorage.getItem("token"), data, this.$route.params.type)
      console.log(result)
      if (result.message) {
        this.err = result.message
        console.log("Серая кнопка")
      } else {
        window.location.href = "/category/all"
      }
    },
    addLinkInterval() {
      this.arrayInterval.push({
        id:this.arrayInterval.length, link:"", option: 10
      })
    },
    addMeaning(id) {
      if(this.arrayMeaning.includes(id)) {
        document.getElementById("mean"+id).classList.remove("class_gray");
        this.arrayMeaning = this.arrayMeaning.filter((item) => {
          if(item != id) {
            return true
          }
          return false
        })
      } else {
        this.arrayMeaning.push(id)
        document.getElementById("mean"+id).classList.add("class_gray");
      }
    },
    delMeaning(id) {
      document.getElementById("mean"+id).classList.remove("class_gray");
      this.arrayMeaning.filter((item) => {
        if(item != id) {
          return true
        }
        return false
      })
    },
    addCondition() {
      this.condition.push({})
      this.toggleType1()
    },
    toggleType1() {
      this.typeCondition = "clock"
    },
    toggleType2() {
      this.typeCondition = "w"
    },
    delConditionAll() {
      this.condition = []
      this.typeCondition = null
    },
    delLinkFromArrayCondition(id) {
      this.arrayInterval.splice(id,1)
      if(this.arrayInterval == 0) {
        this.condition = []
        this.typeCondition = null
      } else {
        this.arrayInterval = this.arrayInterval.map( (item, index) => {
          item.id = index
          return item
        })
      }
    }
  }
};
</script>
<style scoped>
.err_good_text {
  width: 100%;
  text-align: left;
  margin-top: 15px;
  font-size: 25px;
  color: #00ff00;
}
.err_good {
  width: 100%;
}
.display_flex_up_down_main {
  display: flex;
  flex-direction: column;
}
.block_continuation_w {
  text-align: center;
  margin-top: 15px;
}
.display_flex_up_down {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 25px;
}
rectangle_grey_middle_inner4 {
  margin-top: 15px;
}
.block_w_down {
  display: flex;
  align-items: flex-end;
  margin-top: 50px;
  justify-content: center;
  flex-direction: column;
}
.inputs_w_block {
  height: 31px;
  border: 1px solid #dedddf;
  border-radius: 3px;
  padding-left: 10px;
  margin-right: 25px;
}
.block_w {
  width: 80%;
}
.text_input_text {
  width: 100%;
  text-align: left;
}
.input_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.block_w_up {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.two_rectangle_middle {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}
.mark_img_category_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.class_gray {
  background-color: black;
}
.class_grey {
  background-color: #e9e9e9;
}
.button_category_edit {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-top: 50px;
}
.button_category_edit1 {
  width: 250px;
  height: 40px;
  color: #009dda;
  border: 2px solid #8ad4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-right: 15px;
}
.button_category_edit2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 300px;
  height: 40px;
  color: #000000;
  border: 2px solid #e9e9e9;
}
.text_grey_middle_redirect {
  text-align: left;
  width: 100%;
}
.block_rectagles_grey_redirect {
  width: 114px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.type_rectangle_grey {
  width: 38px;
  height: 38px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.type_rectangle_grey > img {
  width: 28px;
  height: 28px;
}
.class_rectangle_grey_middle_text {
  margin-top: 15px
}
.class_rectangle_grey_small_p_cross {
  width: 100%;
  height: 100%;
}
.rectangle_grey_middle_inner1 {
  width: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rectangle_grey_middle_inner2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rectangle_grey_middle_inner3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rectangle_grey_middle_inner4 {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.class_rectangle_grey_middle_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.class_rectangle_grey_small_cross {
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #ff9900;
}
.class_rectangle_grey_small_plus {
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #77dd77;
}
.rectangle_grey_small_text {
  color: #000000;
  margin-bottom: 0px;
}
.block_rectangle_grey {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.class_rectangle_grey_small {
  width: 100%;
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 15px 15px 0 0 ;
  background-color: #e9e9e9;
}
.class_rectangle_grey_middle {
  width: 100%;
  min-height: 93px;
  border: 3px solid #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  min-height: 400px;
}
.utm_block_warning_blue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  background-color: #8ad4ff;
  border-radius: 15px;
  margin-top: 15px;
}
.utm_block_warning_blue > p {
  color: #009dda;
  font-size: 20px;
  margin-bottom: 0px;
}
.input_utm_redirect {
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
  margin-right: 25px;
}
.text_utm_inner1 {
  text-align: left;
  font-weight: bold;
}
.block_redirect_utm_inner1 {
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.block_redirect_utm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.inputs_nickname_redirect {
  display: flex;
}
.redirect_input_nick_name1 {
  width: 250px;
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
}
.redirect_input_nick_name2 {
  width: 150px;
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
}
.mark_img_category_text {
  text-align: left;
  width: 100%;
  font-weight: bold;
}
.wrapper_edit_category {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #e9e9e9;
}
.block_edit_category_text {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.block_edit_category_text2 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.wrapper_edit_category_inner {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
  margin: 50px;
}
.mark_img_category_inner {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.mark_img_category {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}
.block_icon_redirect {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #DEDDDF;
  width: 48px;
  height: 48px;
}
.block_category_edit_categories_name {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.redirect_placeholder_input {
  text-align: left;
  font-weight: bold;
}
.block_redirect_categories {
  display: flex;
  width: 48%;
  flex-direction: column;
  justify-content: flex-start;
}
.redirect_select_categories {
  width: 90%;
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
  margin-right: 25px;
}
.redirect_input_name {
  width: 100%;
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
}
.block_redirect_name {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.block_icon_redirect > img {
  width: 38px;
}
.block_category_edit_link {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.redirect_input_name_link {
  width: 100%;
  height: 31px;
  border: 1px solid  #DEDDDF;
  border-radius: 3px;
  padding-left: 10px;
  margin-right: 25px;
}
.textarea_edit_placeholder {
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding-left: 10px;
}
.textarea_edit_category {
  width: 100%;
  min-height: 62px;
  border: 1px solid  #DEDDDF;
  border-radius: 6px;
}
.block_textarea_edit_category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
}
@media screen and (max-width: 1070px) {
  .block_w_up {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .mark_img_category {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .input_utm_redirect {
    width: 100%;
  }
  .block_w_down {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .input_text {
    margin-top: 15px;
  }
  .button_category_edit {
    flex-wrap: wrap;
  }
  .button_category_edit1 {
    margin-bottom: 15px;
  }
  .two_rectangle_middle {
    flex-direction: column;
    align-items: flex-start;
  }
  .rectangle_grey_middle_inner3 {
    margin-top: 15px;
  }
  .rectangle_grey_middle_inner4 {
    margin-top: 15px;
  }
}
@media screen and (max-width: 600px) {
  .mark_img_category {
    flex-wrap: wrap;
  }
  .class_rectangle_grey_middle_inner {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .block_category_edit_categories_name {
    flex-wrap: wrap;
  }
  .block_category_edit_categories_name {
    flex-direction: column;
  }
  .block_redirect_name {
    margin-top: 15px;
  }
  .redirect_select_categories {
    width: 100%;
  }
  .inputs_nickname_redirect {
    flex-direction: column;
  }
  .two_rectangle_middle {
    margin-top: 25px;
  }
  .inputs_w_block {
    width: 100%;
  }
}
@media screen and (max-width: 405px) {

  .button_category_edit {
    width: 300px;
  }
}
@media screen and (max-width: ) {
  .input_text{
  width: 100%;
}
{
  flex-direction: column;
}
}
</style>