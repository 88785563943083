<template>
        <div class="dde">
            <tr class="view">
                <td @click="accord($event)" class="nomertable" style="color: #333333; font-size: 16px; text-decoration: underline;"> {{data.id}} </td>
                <td style="width: 20%;">
                  <div v-if="data.service.type == 'sub'">
                    <img style="width: 40%;" :src="
                    'https://api.neearby.com/api/subscriptions/photo/' + data.service.item.id" alt="">
                    <p style="padding: 0;
    margin: 0;padding-top: 10px;color: #767676;
    font-weight: 500;">Подписка</p> 
                    </div>
                    <div v-if="data.service.type == 'tovar'">
                     <img style="width: 40%;"  :src="
                    'https://api.neearby.com/api/publication/storage/' + data.service.item.img" alt=""> 
                     <p style="padding: 0;
    margin: 0;padding-top: 10px;color: #767676;
    font-weight: 500;">Товар</p>
                    </div>
                    <div v-if="data.service.type == 'post'">
                     <p style="padding: 0;
    margin: 0;color: #767676;
    font-weight: 500;">Пост</p>
                    </div>
                </td>    
                <td>
                    <p v-if="data.service.isDelete == true" style="margin: 0;
                            background-color: #f46161;
                            border-radius: 2px;
                            display: inline-block;
                            font-size: 12px;
                            padding: 3px 25px;
                            color: #fff;
                            text-align: center;
                            ;">Удален</p>   
                     <p v-if="data.service.status == true && data.service.type == 'sub' && data.service.isDelete == false" style="margin: 0;
                            background-color: rgb(108, 199, 34);
                            border-radius: 2px;
                            display: inline-block;
                            font-size: 12px;
                            padding: 3px 25px;
                            color: #fff;
                            text-align: center;
                            ;">Активна</p> 
                    <p v-if="data.service.status == false && data.service.type == 'sub' && data.service.isDelete == false" style="margin: 0;
                            background-color: #f46161;
                            border-radius: 2px;
                            display: inline-block;
                            font-size: 12px;
                            padding: 3px 25px;
                            color: #fff;
                            text-align: center;
                            ;">Неактивна</p>         
                    <p v-if="data.status == 'zakritiy' && data.service.type != 'sub' && data.service.isDelete == false" style="margin: 0;
                            background-color: rgb(108, 199, 34);
                            border-radius: 2px;
                            display: inline-block;
                            font-size: 12px;
                            padding: 3px 25px;
                            color: #fff;
                            text-align: center;
                            ;">Выполнен</p> 
                    <p v-if="data.status != 'zakritiy' && data.service.type != 'sub' && data.service.isDelete == false" style="margin: 0;
                            background-color: #2196f3;
                            border-radius: 2px;
                            display: inline-block;
                            font-size: 12px;
                            padding: 3px 25px;
                            color: #fff;
                            text-align: center;
                            ;">Ожидает</p>
                            </td>
                <td style="color: #707070;
                font-size: 13px;"> {{data.createdAt}} </td>
                <td> <p v-if="data.status == 'zakritiy'" style="border: none;
                        color: #000;
                        position: relative;
                        font-style: normal;
                        font-size: 14px;
                        cursor: auto;
                        font-weight: normal;
                        margin: 0;">Оплачен</p>

                        <p v-if="data.status == 'process' && data.service.isDelete == false" style="border: none;
                        color: #000;
                        position: relative;
                        font-style: normal;
                        font-size: 14px;
                        cursor: auto;
                        font-weight: normal;
                        margin: 0;">Ожидание оплаты</p>

                        <p v-if="data.status == 'process' && data.service.isDelete == true" style="border: none;
                        color: #000;
                        position: relative;
                        font-style: normal;
                        font-size: 14px;
                        cursor: auto;
                        font-weight: normal;
                        margin: 0;">Отменено</p>

                        <p  v-if="data.status == 'waiting'" style="border: none;
                        color: #000;
                        position: relative;
                        font-style: normal;
                        font-size: 14px;
                        cursor: auto;
                        font-weight: normal;
                        margin: 0;">Ожидает подтверждения</p>

                        <p  v-if="data.status == 'rejected'|| data.status == 'EXPIRED' "  style="border: none;
                        color: #000;
                        position: relative;
                        font-style: normal;
                        font-size: 14px;
                        cursor: auto;
                        font-weight: normal;
                        margin: 0;">Отменен</p> 
                </td>
                <td> <p style="font-size: 14px;
                        color: #707070;margin: 0;">{{data.cost}} руб.</p> </td>
              <td style="max-width: 129px">
                <div class="block_meaning_main">
                  <p style="margin-top: 17px" v-if="data.service.item.referral">Партнерская</p>
                  <div v-for="item in data.meaning.red" :key="item" class="block_meaning_main_inner">
                    <a href="#">
                      <div class="block_icon_redirect">
                        <img :src="getImgUrl(item +'.svg')" alt="" class="img_redirect_table_main">
                      </div>
                    </a>
                  </div>
                </div>
              </td>
                <td style="color: #707070;
                font-size: 13px;"> <p v-if="data.status == 'zakritiy' " style="color: #707070;
                font-size: 13px;">{{data.updatedAt}}</p> </td>
           </tr>
           
            <tablestattr :data="data" />
        </div>

</template>  

<script>
import tablestattr from "../components/tablestattr.vue";
export default {
    components: {tablestattr},
    props: {
        data: {}
    },
    methods: {
      getImgUrl(pic) {
        return require('../assets/'+pic)
      },
    accord(event){
      console.log(1);
      console.log(event.target);
      event.target.parentElement.classList.toggle("open");
      event.target.parentElement.nextSibling.classList.toggle("open");
        },
    },
    data () {
        return {
            sumthink: true,
            donthink: false
        };
    },
    mounted() {
        
    },
}
</script>

<style>
.block_meaning_main {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 129px;
  flex-wrap: wrap;
  color: rgb(112, 112, 112);
  font-size: 13px;
}
.img_redirect_table_main {
  width: 30px;
}
.block_meaning_main_inner {
  margin-right: 3px;
  margin-bottom: 3px;
}

</style>