<template>
  <div class="maincont">
    <div class="forms">
      <div class="formbg-outer">
        <div class="formbg">
          <div class="formbg-inner">
            <span class="addword">Добавить запрещенное слово</span>
            <div class="field padding-bottom--24">
              <input v-model="banwordd" type="text" name="text">
              <div class="field fieldsimbit">
                <button style="background: #ff4911;
    color: white;" class="fieldestsea" @click="addwords">Добавить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
    <b-table
      id="my-table"
      :fields="fields"
      :items="users"
      :per-page="perPage"
      :current-page="currentPage"
      small
    >
      <template #cell(Индекс)="data">
        {{ data.item.id }}
      </template>
      <template #cell(ЗапрещенноеСлово)="data">
        {{ data.item.word }}
      </template>
      <template #cell(Действие)="data">
        <b-button @click="unban(data.item.id)"> Удалить</b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>

  </div>
</template>
<script>
import Api from "../api";

export default {
  name: "adminn",
  methods: { 
    async addwords() {
      let result = await Api.users.addbanword(localStorage.getItem("token"), {word: this.banwordd});
      if (result == true) {
        window.location.reload();
      }
    },
    async unban(id) {
      let result = await Api.users.deleteWord(localStorage.getItem("token"), id);
      if (result == true) {
        window.location.reload();
      }
      console.log(result);
    },
    smth() {
      console.log("sdalkhlas");
    }
  },
  data() {
    return {
      userw: false,
      fields: [
        "Индекс",
        "ЗапрещенноеСлово",
        "Действие"
      ],
      users: {},
      perPage: 50,
      currentPage: 1,
      banwordd: ""
    };
  },
  computed: {
    rows() {
      return this.users.length;
    }
  },
  async mounted() {
    const result = await Api.users.getBannedWord(localStorage.getItem("token"));
    this.users = result;
    console.log(result);
  }
};
</script>
<style lang="css">
.maincont {
  padding: 3%;
}

.card__content {
  margin-top: 40px;
}

.fieldsimbit {
  padding-top: 24px;
}

.fieldsimbit {
  padding-bottom: 24px;
  width: 100%;
}

.formbg-inner {
  padding: 48px;
}

.addword {
  padding-bottom: 15px;
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}

.formbg {
  margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
.field input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline: none;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.fieldestsea {
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline: none;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}
</style>
